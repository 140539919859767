import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
import SEO from '../components/seo/seo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="FAQ" pathname="/info" mdxType="SEO" />
    <h2>{`forms of payment`}</h2>
    <p>{`I accept major credit cards such as Visa, MasterCard, American Express, and
Discover or direct payments through ShopPay, PayPal or ApplePay.`}</p>
    <h2>{`shipping`}</h2>
    <p>{`As I have a family and the world is a bit crazy right now, it may take up to a `}<strong parentName="p">{`week`}</strong>{`  for your order to be shipped out.`}</p>
    <p>{`In the U.S. items will arrive between 2-5 days after they are shipped`}</p>
    <p>{`Items to Canada and Mexico take a little over a week`}</p>
    <p>{`Items anywhere else will usually take around 2 weeks`}</p>
    <p>{`You will get a shipping confirmation in your email the day your order has been shipped.`}</p>
    <h2>{`do you ship to my country`}</h2>
    <p>{`Due to COVID-19 I am currently `}<strong parentName="p">{`only`}</strong>{` shipping to the United States, Canada, and Europe`}</p>
    <h2>{`order tracking`}</h2>
    <p>{`Once the order has been shipped, a tracking link will be emailed to you in the
email you provided upon purchase. It may take up to 24 hours for tracking
information to show up.`}</p>
    <p>{`International tracking might not extend past the United States, however your
order is still on it’s way.`}</p>
    <h2>{`return policy`}</h2>
    <p>{`I want you to be 100% satisfied with your DVLPR purchase. Unworn/unwashed items
can be returned or exchanged with in 5 days of delivery. Please note that
original shipping charges are non-refundable.`}</p>
    <p>{`If I made a mistake or the item arrived defective, please contact me and I’ll
make it right.`}</p>
    <h2>{`how do i return an item?`}</h2>
    <p>{`Include a copy of the packing slip or confirmation email from your order, and
let me know what the new size or item is that you are exchanging it for. (As
some items sell out, I can’t guarantee that all items and sizes will be in stock
for exchanges)`}</p>
    <h2>{`customs`}</h2>
    <p>{`Shipments outside of the USA may incur customs fees depending on your country of
residence. The fee may vary depending on your order value, country limits, and
other factors.`}</p>
    <p>{`Customs policies vary from country to country; if you have any concerns at all,
then you should contact your local customs office.`}</p>
    <p>{`DVLPR does not take responsibility for possible fees.`}</p>
    <h2>{`are you planning on restocking `}{`[item]`}{`?`}</h2>
    <p>{`If there is enough interest in a product, I’ll happily do another run, so let me
know on IG, Twitter or via email!`}</p>
    <h2>{`how do your shirts fit?`}</h2>
    <p>{`Shirts are TTS (true to size), meaning whatever size you wear from your other favorite brands or department stores, will be the same size to purchase.`}</p>
    <h2>{`t-shirt care`}</h2>
    <p>{`Machine wash `}<strong parentName="p">{`cold`}</strong>{`, inside out, with like colors`}</p>
    <p>{`Only non-chlorine bleach`}</p>
    <p>{`Tumble dry low`}</p>
    <p>{`Medium iron`}</p>
    <p>{`Do not iron design`}</p>
    <p>{`Do not dry clean`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      